import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/projects.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/project.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Projekti
              </h2>
              <p id="escape2">
                Ako vam je potreban pregled svih vaših zadataka, to možete lako
                da uradite pomoću naše funkcije Projekti, koja vam omogućava da
                grupišete povezane zadatke i pratite njihov napredak. Projekti
                su efikasna funkcija gde možete videti kako projekat napreduje -
                od početka do kraja. Tafoma vas štedi od glomaznih platformi za
                upravljanje projektima i predstavlja vrhunski isplativ softver
                za timski rad.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              <StaticImage
                src={"../../assets/images/features/project.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
