import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/notes.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/notes.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Beleške
              </h2>
              <p id="escape2">
                Beleške sadrže uređivanje u realnom vremenu, automatsko čuvanje
                i mogućnost brzog deljenja teksta sa kolegama; izbegavajući
                mogućnost kontraproduktivnih sastanaka. Dalje povećanje
                produktivnosti, beleške može da vidi svako u vašem timu.
                Dizajnirane za neprekidan timski rad, Tafoma beleške su
                neprocenjiv alat.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              <StaticImage
                src={"../../assets/images/features/notes.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
