import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import feature from "../../assets/images/features/tasks.png";
import icon from "../../assets/images/features/icons/tasks.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              {/* <img className="features" src={feature} alt="banner" /> */}
              <StaticImage
                src={"../../assets/images/features/tasks.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
                loading="eager"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Zadaci
              </h2>
              <p id="escape2">
                Modul zadataka je osnovna funkcionalnost Tafome. Možete kreirati
                zadatke za sebe ili dodeliti zadatke bilo kom od svog tima.
                Uključujući metodologije, pružamo vam maksimalnu fleksibilnost.
                Zadaci se mogu sastojati od: opisa, komentara, liste obaveza,
                odeljaka o troškovima i upravljanju vremenom. Štaviše, možete
                podesiti datume početka i krajnjeg roka, označite nivo
                prioriteta projekta, postavite podsetnike, pa čak i pokažete
                procenat završenog zadatka. Naravno, takođe možete priložiti
                datoteke, uključujući slike, dokumente i PDF-ove.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
