import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/video_meeting.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/video_meeting.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Video sastanci
              </h2>
              <p id="escape2">
                Koristite našu aplikaciju za video konferencije da efikasno
                sarađujete na daljinu sa svima u vašem timu, kako na desktopu
                tako i na mobilnom uređaju. Možete čak pozvati nekoga ko nije
                pretplaćen na Tafoma na sastanak. Tafoma video konferencije vam
                omogućavaju da proširite obim svog poslovanja bez ugrožavanja
                produktivnosti i angažovanja. Tafoma vam omogućava da imate
                neograničeno vreme deljenja ekrana sa do 50 korisnika.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
