import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/documents.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/documents.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Dokumenti
              </h2>
              <p id="escape2">
                Tafoma dokumenti vam omogućavaju da kreirate jednostavne
                dokumente za obradu teksta kao što su pisma i izveštaji i da ih
                uređujete pomoću osnovnih alata ili da ih učinite razigranim -
                možete dodati boje, koristiti ilustracije, pisati različitim
                fontovima i veličinama i koristiti tabele, linije i
                formatiranje.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              {/* <img className="ptb-50" src={feature} alt="banner" /> */}
              <StaticImage
                src={"../../assets/images/features/documents.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
