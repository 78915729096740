import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/social_wall.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/social_wall.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Društveni zid
              </h2>
              <p id="escape2">
                Naš društveni zid je izgrađen od temelja, obogaćujući kulturu
                kompanije dajući vašem timu povezanije, informisanije i
                harmoničnije iskustvo. Tafomin društveni zid je hit među
                korisnicima. Dodaje jedinstvenost komunikaciji sa ljudima iz
                okruženja, kontaktima i društvenim događajima. Svideće vam se
                koliko je lako i zadovoljavajuće kreirati post koji ceo vaš tim
                može da vidi.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
