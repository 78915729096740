import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/calendar.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/calendar.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Kalendar
              </h2>
              <p id="escape2">
                Tafoma je platforma za upravljanje vremenom, od vremenskih
                blokova do podataka o produktivnosti. Naš kalendar pruža jasan
                pregled vremenske linije zadataka, podsetnika i vašeg celokupnog
                dnevnog reda. Možete lako da delite događaje i obaveštenja sa
                svojim kolegama i oni će moći da ih vide u svom kalendaru.
                Tafoma funkcija zakazivanja događaja i pozivi nisu ograničeni
                samo na članove tima, već i na spoljne učesnike.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              <StaticImage
                src={"../../assets/images/features/calendar.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
