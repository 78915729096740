import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import Tasks from "../components/Features/Tasks";
import Projects from "../components/Features/Projects";
import Chat from "../components/Features/Chat";
import Talk from "../components/Features/Talk";
import VideoMeetings from "../components/Features/Video_Meetings";
import Calendar from "../components/Features/Calendar";
import Drive from "../components/Features/Drive";
import Notes from "../components/Features/Notes";
import SocialWall from "../components/Features/Social_Wall";
import AddressBook from "../components/Features/Address_Book";
import Search from "../components/Features/Search";
import GanttChart from "../components/Features/Gantt_Chart";
import Documents from "../components/Features/Documents";
import Spreadsheets from "../components/Features/Spreadsheets";
import Presentations from "../components/Features/Presentations";

const Features = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner pageTitle="Tafoma funkcionalnosti" />
      <Tasks />
      <Projects />
      <Chat />
      <GanttChart />
      <VideoMeetings />
      <Calendar />
      <Drive />
      <Notes />
      <SocialWall />
      <AddressBook />
      <Search />
      {/* <Talk /> */}
      <Documents />
      <Spreadsheets />
      <Presentations />
      <Footer />
    </Layout>
  );
};

export default Features;
