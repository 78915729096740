import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/search.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/search.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Pretraživanje
              </h2>
              <p id="escape2">
                Nakon nekog vremena, vaš radni prostor će biti pretrpan
                desetinama projekata i stotinama zadataka i datoteka. Zbog toga
                je Tafoma opremljen sveobuhvatnim pretraživačem kako bi bio
                siguran da možete pronaći sve što vam je potrebno za nekoliko
                sekundi. Samo unesite reč ili frazu i pretraživač će pretražiti
                svaki zadatak, projekat, komentar, razgovor i dokument koji ste
                priložili.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
