import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/presentations.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/presentation.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Prezentacije
              </h2>
              <p id="escape2">
                Prezentacije na Tafomi mogu se koristiti na više različitih
                efikasnih načina za komunikaciju sa vašim timom. Slajdovi su
                potpuno prilagodljivi vašim potrebama. U zavisnosti od vašeg
                pristupa, možda ćete želeti da imate prezentaciju koja je bogata
                tekstom, slikama ili nekom kombinacijom oba. Kombinovanje ova
                dva pristupa daje vašem timu prednosti i vizuelnih pomagala i
                beleški.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              {/* <img className="ptb-50" src={feature} alt="banner" /> */}
              <StaticImage
                src={"../../assets/images/features/presentation.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
