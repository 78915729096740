import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/gantt_chart.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/gantt_chart.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Gantov grafikon
              </h2>
              <p id="escape2">
                Ostanite na vrhu upravljanja svojim timom koristeći našu
                elegantno dizajniranu alatku Gantovog grafikona za kreiranje
                sveobuhvatnog i lako čitljivog pregleda vremenske linije
                projekta. Dajući vizuelni pregled prekretnica i drugih ključnih
                datuma, ovi grafikoni nude razumljiviji i pamtljiviji metod
                održavanja zadataka i projekata zasnovanih na vremenskim
                okvirima, bilo da se prate na dnevnoj, nedeljnoj, mesečnoj ili
                godišnjoj bazi.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              <StaticImage
                src={"../../assets/images/features/gantt_chart.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
