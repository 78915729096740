import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/drive.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/drive.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Drive
              </h2>
              <p id="escape2">
                Tafoma Drive ima jednostavnu funkciju: bezbedno skladištenje i
                nesmetano sinhronizovanje svih vaših dokumenata i datoteka u
                oblaku tako da im se može pristupiti gde god da se nalazite. Sa
                Drive možete brzo i lako da delite datoteke ili fascikle sa
                svojim timom. Tafoma koristi dvostepenu enkripciju tako da
                nikada ne morate da brinete o privatnosti.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
