import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/chat.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/chat.png"}
                alt=""
                className="features"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} /> Chat
              </h2>
              <p id="escape2">
                U doba rada na daljinu, efikasan alat za ćaskanje može brzo
                postati okosnica vašeg poslovanja. Tafomina soba za ćaskanje je
                sveobuhvatan alat za umrežavanje za timsku saradnju u realnom
                vremenu sa mogućnostima deljenja datoteka i omogućava svojim
                korisnicima da razmenjuju tekstualne poruke, slike, video snimke
                i hiperlinkove.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
