import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import icon from "../../assets/images/features/icons/address_book.svg";

const ServicesOne = () => {
  return (
    <section className="project-start-area ptb-25 bg-e7edf9">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mobile-only">
            <div className="text-center">
              <StaticImage
                src={"../../assets/images/features/address_book.png"}
                alt=""
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2 id="escape">
                <img className="icon" src={icon} />
                Adresar
              </h2>
              <p id="escape2">
                Unesite sve svoje kontakte na jedno mesto. Pomoću ove funkcije
                takođe možete grupisati svoje kontakte po kupcima, dobavljačima,
                izvođačima, podizvođačima, pacijentima itd. Pored toga, možete
                da povežete zadatke ne samo sa projektom, već i sa kontaktom i
                vidite sa koliko zadataka je kontakt povezan i koliko zadataka
                je još uvek aktivno.
              </p>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 desktop-only">
            <div className="text-center features">
              <StaticImage
                src={"../../assets/images/features/address_book.png"}
                alt=""
                className="features ptb-50"
                imgClassName="features-img"
                formats={["AUTO", "WEBP", "AVIF"]}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;
